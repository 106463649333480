/* Updated styles for cta-text-item */


@keyframes fadeInDown {

    0%,
    30% {
        opacity: 0;
        transform: translateY(-20px) scale(1);
    }

    50% {
        opacity: 1;
        transform: translateY(-20px) scale(1);
    }

    90% {
        opacity: 1;
        transform: translateY(-20px) scale(1);
    }

   90%, 100% {
        opacity: 0;
        transform: translateY(-20px) scale(1);
    }
}



@keyframes slideFadeIn {
    from {
        opacity: 0;
        transform: translateX(50%);
     
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}




@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  .fadeIn {
    animation: fadeIn 1s ease forwards;
  }
  
  .fadeOut {
    animation: fadeOut 1s ease forwards;
  }
  
  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
.ctahero-text-item {
    font-weight: 700;
    font-style: italic;
    display: inline-block;
    padding: 0.4em 4.5em;
    margin: 0 auto;
    animation: slideFadeIn 2s ease-out forwards;

    /* Centered gradient radiating outwards */
    background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 35%, rgba(255, 255, 255, 1) 65%, rgba(255, 255, 255, 0) 100%);
    color: #000;

    /* Adjusted to span 65% of the viewport width */

    /* Matches the width */
    box-sizing: border-box;
}


/* Ensure text remains readable on very small or very large screens */
@media (max-width: 767px) {
    .ctahero-text-item {
        font-size: 1.25rem;/* Adjust font size for smaller screens */
        max-width:100vw;
    }
    .ctahero-button {
        width: 80vw;
    }

}


@media (min-width: 768px) {
    .ctahero-text-item {
        font-size: 2.25rem;/* Cap font size for larger screens to prevent it from becoming too large */
        max-width:100vw;

    }
    .ctahero-button{
        width: 50vw;
    }
}

@media (min-width: 1024) {
    .ctahero-text-item {
        font-size: 2.5rem;/* Cap font size for larger screens to prevent it from becoming too large */
        max-width: 50vw;

    }
    .ctahero-button{
        width: 30vw;
    }


}

@keyframes noAnimation {
    0%, 50% {
        opacity: 1;
        transform: scale(0.95);
    }
    50%, 100% {
        opacity: 1;
        transform: scale(1);
    }
  }


.ctahero-button {
    box-shadow: 0 2px 15px rgba(255, 255, 255, 0.5);
    font-size: 2rem;
    opacity: 0;
    /* Update to include a delay and make the shine occur every 10 seconds */
    animation: fadeInAndZoom 2s ease-in forwards;
    animation-delay: 0s, 2s; /* No delay for fadeInAndZoom, 2s delay for shine */
    position: relative;
    left: 0;
    bottom:0;
    display: block;
    /* Allowing button to stretch */
    background-color: #be6544;
    color: white;
    padding: 10px 20px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.6); */
    margin: 20px auto;
    background-size: 100%;
    background-position: 0 100%;
}


.ctahero-button:hover {
    /* Adjusted for a softer glow effect */
    box-shadow: 0 2px 15px rgba(255, 255, 255, 0.3);
}


/* Immediate effect on button press */
.ctahero-button:active {
    @apply scale-95;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

}

.ctaclick-button {
    box-shadow: 0 2px 15px rgba(255, 255, 255, 0.5);
    font-size: 1rem;
    opacity: 0;
    /* Update to include a delay and make the shine occur every 10 seconds */
    animation: noAnimation 0s ease-in forwards; 
    position: relative;
    left: 0;
    bottom:0;
    display: block;
    background-color: #1c3bd2;
    color: white;
    padding: 10px 10px;
    border: 2px solid #ffffff;
    border-radius: 10px;
    /* box-shadow: 0 0 20px rgba(255, 255, 255, 0.6); */
    margin: 2px auto;
    background-size: 100%;
    background-position: 0 100%;
}

.expanded-text {
    margin-top: 20px; /* Adjust this value as needed */
    transition: max-height 0.5s ease-out, opacity 0.5s ease-out;
    max-height: 0;
    overflow: hidden;
    opacity: 0;
}

.residentialbanner.isExpanded .expanded-text {
    max-height: 100px; /* Adjust this value as needed */
    transition: max-height 2s ease-out;

    opacity: 1;
}

.ctaclick-button:hover {
    /* Adjusted for a softer glow effect */
    box-shadow: 0 2px 15px rgba(255, 255, 255, 0.3);
}


/* Immediate effect on button press */
.ctaclick-button:active {
    @apply scale-95;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);

}

.bg-red-500-important {
    background-color: #f56565 !important; 
}

@keyframes fadeInAndZoom {
    0%, 50% {
        opacity: 1;
        transform: scale(0.95);
    }
    50%, 100% {
        opacity: 1;
        transform: scale(1);
    }
}


/* Adjust for a quick shine effect */
@keyframes shine {
    0% { background-position: left top; }
    100% { background-position: right bottom; }
}