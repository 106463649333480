@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer components {
    .hero-overlay {
        @apply bg-opacity-30;
    }

    .hero-title {
        @apply textShadow-md;
    }

    .hero-subtitle {
        @apply textShadow;
    }

    .estimate-button {
        @apply transition-colors ease-in-out duration-300;
    }


    /* Additional shine effect*/
    .shine-effect::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent);
        animation: shine 3s ease infinite;
    }

    .nav-fixed-top {
        @apply fixed top-0 w-full bg-[#005e30] text-white shadow;

    }

}

/* Custom CSS for shine effect */
@keyframes shine {
    0% {
        left: -100px;
    }

    20% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}

/* Existing .hero class with modifications for parallax and sizing */
.hero {
    width: 100%;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: flex-end;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: cover;
    min-height: 70vh;
    background-attachment: fixed;
    /* Key for parallax effect */
    overflow: hidden;

}



@keyframes pulse-logo {

    0%,
    50% {
        transform: scale(0.75);
        opacity: 0.85;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }



}

.logo-animate {
    animation: pulse-logo 1.5s ease-in-out;
}

@keyframes cascade {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}




.cascade-animation>* {
    opacity: 0;
    /* Make sure elements are initially hidden */
    animation: cascade 0.5s ease-out forwards;
}

.cascade-animation>*:nth-child(1) {
    animation-delay: 0.1s;
}

.cascade-animation>*:nth-child(2) {
    animation-delay: 0.2s;
}

.cascade-animation>*:nth-child(3) {
    animation-delay: 0.3s;
}

.cascade-animation>*:nth-child(4) {
    animation-delay: 0.3s;
}

/* Custom CSS for shine effect */
@keyframes shine {
    0% {
        left: -100px;
    }

    20% {
        left: 100%;
    }

    100% {
        left: 100%;
    }
}


/* Base styles for .nav-link */
.nav-link {
    @apply py-2 px-4 font-semibold rounded-lg;
    @apply border border-gray-300 text-gray-900 bg-white;
    @apply transition-all duration-300 ease-in-out;
    @apply shadow-md relative;
    /* Initial shadow for depth and positioning */
    /* Initial box-shadow for a soft glow effect */
    box-shadow: 0 2px 15px rgba(255, 255, 255, 0.5);
    z-index: 10;
}

/* Hover state for .nav-link */
.nav-link:hover {
    @apply shadow-lg;
    /* Enhance shadow and scale for hover effect */
}

/* Active (click) state for .nav-link */
.nav-link:active {
    @apply scale-95;
    /* Scale down to simulate pressing */
    /* Add further styles if needed for the active state */
}


/* Enhancing circular-button with a metallic border effect */
.circular-button {
    position: relative;
    /* Needed for pseudo-element positioning */
    @apply block flex items-center justify-center transition duration-300 ease-in-out;
}

.circular-button::before {
    content: '';
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    /* Slightly larger to create the border effect */
    border-radius: 50%;
    /* Ensures the pseudo-border is circular */
    background: linear-gradient(45deg,
            #ccc 5%,
            /* Lighter shade compared to previous */
            #fff 15%,
            /* Increased white area for brightness */
            #eee 30%,
            /* Very light gray */
            #fff 50%,
            /* Maximizing white presence */
            #eee 70%,
            /* Very light gray for symmetry */
            #fff 85%,
            /* Increased white area towards the end */
            #ccc 95%
            /* Light shade for a subtle metallic finish */
        );

    z-index: -1;
    /* Ensures the pseudo-border appears behind the button */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    /* Mimics the shadow effect for depth */
}



/* Immediate effect on button press */
.circular-button:active {
    @apply scale-95;


}

.circular-image {

    @apply transition transform w-16 h-16 duration-300 ease-in-out;
    border-radius: 50%;
    /* Ensures the image is perfectly circular */

}

@media (min-width: 1500px) {
    .wood-container {
        background-size:
            2000px var(--wood-top-height),
            2000px calc(100% - var(--wood-top-height) - var(--wood-bottom-height)),
            2000px var(--wood-bottom-height);
    }

    .text-container-blur {
        width: 70%;
    }

    .logo-container img {
        max-width: calc(5vw);
        min-width: 125px;
    }
}

@media (min-width: 1024px) and (max-width: 1499px) {
    .wood-container {
        background-size:
            1528px var(--wood-top-height),
            1528px calc(100% - var(--wood-top-height) - var(--wood-bottom-height)),
            1528px var(--wood-bottom-height);
    }

    .text-container-blur {
        width: 80%;
    }

    .logo-container img {
        max-width: calc(9vw);
        min-width: 75px;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .nav-link {
        @apply text-xl;
    }

    .logo-container img {
        width: calc(15vw);
        min-width: 70px;
    }
    .wood-container {
        background-size:
            1250px var(--wood-top-height),
            1250px calc(100% - var(--wood-top-height) - var(--wood-bottom-height)),
            1250px var(--wood-bottom-height);
    }
}

@media (max-width: 767px) {
    .wood-container {
        background-size:
            1200px var(--wood-top-height),
            1200px calc(100% - var(--wood-top-height) - var(--wood-bottom-height)),
            1200px var(--wood-bottom-height);
    }
    .logo-container img {
        width: calc(20vw);
        min-width: 70px;
    }

    .nav-link {
        @apply text-sm px-3;
        /* Smaller text and reduced padding */
    }

    .logo-container img {
        width: calc(8vw);
        min-width: 65px;
    }

    .nav-link {
        min-width: 100px;
        /* This will make sure the width is at least as wide as the content */
    }

    .cta-text span {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }

    .frame {
        margin: 0.5vw;
    }


}



@media (max-width: 400px) {

    /* Adjust the padding and font size of nav links to prevent overflow */
    .nav-link {
        @apply text-xs px-2;
        /* Smaller text and padding */
    }

    .wood-container {
        background-size: 
        1000px var(--wood-top-height), /* Adjusted previously for height */
        1000px calc(100% - var(--wood-top-height) - var(--wood-bottom-height)), /* Adjusted previously for height */
        1000px var(--wood-bottom-height); /* Adjusted previously for height */
    }
   
    .logo-container img {
        width: calc(30vw);
        min-width: 65px;
    }


}

/* Adjust the logo-container positioning */
.logo-container {
    position: absolute;
    /* or 'fixed', depending on your layout needs */
    top: 2vh;
    /* Navbar height + desired distance from the bottom of the navbar */
    left: 50%;
    /* Center the logo horizontally */
    transform: translateX(-50%);
    /* Adjust for exact centering horizontally */

    /* Responsive width */
    height: auto;
    /* Maintain aspect ratio */
}


.affil-logo-resize {
    width: auto;
    /* Adjust based on parent container's width */
    max-height: 70px;
    /* Maintain aspect ratio */

    max-width: 25vw;
    /* Ensure it does not overflow its container */

}

.metallic-border {
    border-left: 2px solid transparent;
    /* Add left border for larger screens */
    border-right: 2px solid transparent;
    /* Add right border for larger screens */
}


/* Larger text for desktop */
h3 {
    @apply text-2xl;
}

p {
    @apply text-lg;
}


.parallax {
    background-attachment: fixed;
    transform: translateY(-0%);
    /* Adjust based on desired effect */

}

/* Pop-out effect with right and bottom borders */
.pop-out-border {
    border-right: 4px solid #999;
    /* Visible right border */
    border-bottom: 4px solid #999;
    /* Visible bottom border */
    border-top-right-radius: 25px;
    /* Rounded top right corner */
    border-bottom-left-radius: 25px;
    /* Rounded bottom left corner */
}



/* Location: src/styles/index.css */
.metallic-border-bottom {


    position: relative;
    z-index: 1;

    background-image: linear-gradient(to right, silver, grey, silver);
    background-repeat: no-repeat;
    background-size: 100% 3px;
    background-position: bottom;
}


/*.metallic-border to only include top and bottom borders */
.metallic-border {
 
    border-left: none;
    border-right: none;
    /* Apply top and bottom padding only, remove left and right padding */
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 0;
    padding-right: 0;

    background: transparent;
    position: relative;
    z-index: 1;
}

/*  .metallic-border::before to extend only top and bottom pseudo-borders */
.metallic-border::before {
    /* Adjust content, positioning, and styling as before */
    content: '';
    position: absolute;
    /* Extend the pseudo-element to the full width while aligning with top and bottom borders */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    /* Maintain border-radius and apply the gradient background */
    border-radius: inherit;
    background: linear-gradient(45deg,
            #999 5%,
            #fff 10%,
            #ccc 30%,
            #ddd 50%,
            #ccc 70%,
            #fff 80%,
            #999 95%);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    z-index: -1;
}




/* Custom background color */
.bg-earth-tone {
    background-color: #af8a7276;

}

/* Enhancing visibility with inline text styles */
.text-enhanced {
    @apply text-shadow-lg;
}


/*Text Visibility Enhancer */
.text-container-blur {
    background-color: rgba(255, 255, 255, 0.745);
    /* almost imperceptible white background */
    backdrop-filter: blur(40px);
    /* blur effect for the background */
    padding: 15px;
    /* adjusted padding for visual balance */
    border-radius: 8px;
    /* rounded corners for aesthetic */
    display: block;
    /* Change display back to block */
    margin-left: auto;
    /* Auto margins for horizontal centering */
    margin-right: auto;
    /* Auto margins for horizontal centering */
    box-sizing: border-box;
    /* padding included in the element's total width */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* Add shadow for depth */
    margin-bottom: 2rem;
    margin-top: 2rem;
    /* Ensure it takes full width for better alignment */
    width: 98%;

}



/* Located in your main.css or equivalent stylesheet */
.banner-flex-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    /* Allows items to wrap as needed */
    max-width: 2000px;
    margin: 0 auto;
    /* Center the container */
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.745);
    backdrop-filter: blur(40px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
}



/*Text Visibility Enhancer */
.bg-blur {

    background-color: rgba(255, 255, 255, 0.5);
    /* Semi-transparent white background */
    padding: 4px 4px;
    /* Adjust padding as needed */
    border-radius: 4px;
    /* Optional, for rounded corners */
}


.bg-dark-green {
    background-color: #645f53;
    
}



.delay-4000 {
    animation: fadeIn2 1s 4s forwards;
}

@keyframes fadeIn2 {
    to {
        opacity: 1;
    }
}


.residentialbanner {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    animation: fadeIn 1s ease-out;
    z-index: 50;
    position: fixed;
    top: 90px;
    background-color: rgba(255, 255, 255, 0.781); /* Lighter background */
    backdrop-filter: blur(90px);
    padding: 5px;
    border-radius: 4px; /* Half the border radius */
    display: block;
    margin: 5px; /* Half the margin */
    box-sizing: border-box;
    width: fit-content;
    transition: transform 1s ease-out, opacity 0.5s ease-out;
    will-change: transform, opacity;
}




/* Header specific classes with enhancements */
.header-container {
    display: flex;
    /* Use flexbox to center the child */
    justify-content: center;
    /* Center the child horizontally */
    max-width: 2000px;
    /* Maximum width of the container */
    width: 100%;
    /* Make it take the full width of its parent */
    margin: 0 auto;
    /* Center the container itself */
}



.header {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 50;
    position: fixed;
    top: 90px;

    /* Ensure it's above other content but below the extended parallax image */
    /* Smooth transition for any dynamic positioning */
    background-color: rgba(255, 255, 255, 0.562);
    backdrop-filter: blur(10px);
    /*on background*/
    padding: 10px;

    /* adjusted padding for visual balance */
    border-radius: 8px;
    /* rounded corners for aesthetic */
    display: block;
    /* Change display back to block */
    margin: 10px;
    margin: 10px;

    /* Auto margins for horizontal centering */
    box-sizing: border-box;
    /* padding included in the element's total width */
    width: fit-content;

    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    /* Smooth transition for both transform and opacity */
    will-change: transform, opacity;
    /* Optimizes animations */

}


.header-title {
    @apply text-3xl font-bold;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.header-subtitle {
    @apply text-lg;
    text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
}

.header-hidden {
    opacity: 0;
    /* Make header fully transparent */
    transform: translateY(-100%);
    /* Move header out of view */
}


:root {
    --wood-top-height: 282px;
    --wood-bottom-height: 462px;
}

.wood-container {
    background-image: 
        url('../assets/woodgrain-top.jpg'),
        url('../assets/woodgrain-middle.jpg'),
        url('../assets/woodgrain-bottom.jpg');
    background-repeat: no-repeat, no-repeat, no-repeat;
 
    background-position: 
     
    left top, /* Top image starts from the left */
    left calc(var(--wood-top-height)), /* Middle image starts from the left and right after the top image ends */
    left bottom; /* Bottom image starts from the left */
    padding-top: 140px;
    min-height: 800px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}


.frame {
    border: 1px solid #141413;
    /* Light beige border to complement the page */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Soft shadow for depth */
    display: inline-block;
    /* Ensure the frame wraps tightly around the image content */
    background: url('../assets/aboutuswoodframe.jpg');
    /* Path to a subtle texture image */
    background-size: cover;
    background-blend-mode: multiply;
    /* Ensures texture blends with the border color */

}

.outerBevel {
    /* of frame */
    box-shadow: 4px 6px 12px 0 black;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(109, 84, 58) rgb(24, 19, 13) rgb(24, 19, 13) rgb(109, 84, 58);
}



.innerBevel {
    /* Simulating the border effect with a background */
    background: url('../assets/aboutuswoodframe.jpg');
    /* Wood grain background */
    box-shadow: 4px 6px 12px 0 black;
    /* Adds shadow to create depth */
    padding: 5px;
    /* Adjust based on desired border thickness */
}

.map {
    /* including mat and gold beveled edge of mat */
    display: block;
    clear: both;
    height: 100%;
    width: 100%;


    border-width: 3px;
    /* Gold edge of mat */
    border-style: solid;
    border-color: rgb(146, 138, 105) rgb(116, 111, 91) rgb(146, 132, 87) rgb(79, 72, 48);
}


