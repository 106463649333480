

/* Container holding the weeds */
.weed-container {
    position: relative;
    display: flex; /* Change to flex to manage content more effectively */
    flex-direction: column; /* Stack children vertically */
    justify-content: space-between; /* Distribute space between items */
    background-size: cover;
    background-position: center; /* Align the image in the center */
}

@keyframes fadeInDownAndZoom {

    0%,
    30% {
        opacity: 0;
        transform: translateY(-20px) scale(1);
    }

    50% {
        opacity: 1;
        transform: translateY(-20px) scale(1);
    }

    90% {
        opacity: 1;
        transform: translateY(-20px) scale(1);
    }

   90%, 100% {
        opacity: 0;
        transform: translateY(-20px) scale(1);
    }
}


/* Base animation without skew for fallback */
@keyframes shrivelFade {
    0% {
        transform: scale(1);
        /* Start at full size */
        opacity: 1;
        /* Fully visible */
    }



    100% {
        transform: scale(0.2) skewX(var(--skewDeg, 0deg));
        /* Further shrink and skew */
        opacity: 0;
        /* Fade out completely */
        filter: contrast(125%) saturate(20%) brightness(10%);
        /* Further darken */
    }
}



/* Animation applied directly to weeds when container has 'animate' class */
.weed-container.animate .weed {
    animation: shrivelFade 3s forwards;
  }

/* Apply the shrivelFade animation to each weed on hover of the container */
.weed-container:hover .weed {
    animation: shrivelFade 3s forwards;
}


@media (min-width: 1024px) {
    .card-lg-height {
      height: 150%; /* Make the card 50% taller on large screens */
    }
  }

/* Step 1: Define the keyframes for the growth animation */
@keyframes growUpwards {
    from {
      transform: scaleX(0) scaleY(0); /* Start with a scale of 0 (not visible) */
    }
    to {
      transform: scaleX(1) scaleY(1); /* Scale to its full size */
    }
  }
  
  .weed {
    position: absolute;
    width: 100%; /*full width of its container */
    height: 100%; /* full height of its container */
    background-size: contain; /* Adjust this to 'cover' if you want the image to fully cover the element without repeating */
    background-repeat: no-repeat; /* Prevent the image from repeating */
    transform-origin: bottom; /* Keeps scaling anchored */
    z-index: 1; /* Ensure weeds are behind text content */
    animation: growUpwards 5s ease-in-out forwards; /* Keep your animation */
}
  
.text-visible {
    text-shadow: 2px 2px 8px rgba(255, 255, 255, 0.877),  /* Original light shadow for contrast */
    0 0 10px rgba(255, 255, 255, 0.8), /* New: Adds a glow/halo effect around the text */
    0 0 20px rgba(255, 255, 255, 0.8); /* New: Enhance the halo effect for better visibility */
    z-index: 2;
}



/* Base style for mobile and small screens */
.card-text-container-blur {
    background-color: rgba(255, 255, 255, 0.745); /* almost imperceptible white background */
    backdrop-filter: blur(40px); /* blur effect for the background */
    padding: 15px; /* adjusted padding for visual balance */
    border-radius: 8px; /* rounded corners for aesthetic */
    display: block; /* Change display back to block */
    margin-left: auto; /* Auto margins for horizontal centering */
    margin-right: auto; /* Auto margins for horizontal centering */
    box-sizing: border-box; /* padding included in the element's total width */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: 85%; /* Default width for smallest screens */
}

.price-container-blur {
    background-color: rgba(255, 255, 255, 0.745); /* Retaining the almost imperceptible white background */
    backdrop-filter: blur(40px); /* Keeping the blur effect for the background */
    padding: 15px; /* Keeping adjusted padding for visual balance */
    border-radius: 0; /* Removed rounded corners for a more modern, sharp look */
    display: block; /* Keeping display as block */
    margin-left: auto; /* Keeping auto margins for horizontal centering */
    margin-right: auto; /* Keeping auto margins for horizontal centering */
    box-sizing: border-box; /* Keeping padding included in the element's total width */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Retaining shadow for depth */
    border-radius: 10px;
    margin-bottom: 2rem;
    margin-top: 2rem;
    width: fit-content; /* Keeping default width for smallest screens */

    /* Added distinct border */
    border: 2px solid rgba(0, 0, 0, 0.2); /* Adding a light, distinct border for emphasis */

    /* Enhancing font visibility */
    color: #000; /* Making the font color black for better contrast and visibility */
    font-weight: bold; /* Making the font bold to stand out more */
}

/* Medium screens (tablets) */
@media (min-width: 768px) {
    .card-text-container-blur {
        width: 45%; /* Increase width for medium screens */
    }
}

/* Large screens (laptops/desktops) */
@media (min-width: 1024px) {
    .card-text-container-blur {
        width: 30%; /* Set width to 50% for large screens */
    }
}



.text-visible, .bg-blur, .card-text-container-blur {
    position: relative; /* Ensure z-index is respected */
    z-index: 2; /* Maintain this to keep text above .weed elements */
}


.btn-shine:hover {
    position: relative;
    overflow: hidden;
}

.ctapricecard-button {
    transition: transform 0.3s ease-in-out;
    /* Custom CSS for color, border, and shadow */
    /* width: 60%; */
    width: 50%; /* Use fit-content to make the width as wide as its content */
    margin-left: auto; /* Auto margins for horizontal centering */
    margin-right: auto; /* Auto margins for horizontal centering */
    background-color: #696565; /* Replaces bg-terracotta-500 */
    animation: fadeInAndZoom 2s ease-in forwards, shine 3s;
    border: 2px solid #4e6457; /* Nature-themed border */
    color: white; /* Keeps text-white */
    padding: 12px 24px; /* px-6 and py-2, adjust as necessary */
    font-size: 1.125rem; /* text-lg */
    font-weight: bold; /* font-bold */
    border-radius: 10px; /* rounded-full, adjust if you prefer less rounded corners */
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s; /* transition-colors */
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0) 49%, rgba(255, 255, 255, 0.8) 50%, rgba(255, 255, 255, 0) 51%);
    background-size: 400% 400%;
    background-position: 0 100%;
    position: relative;
    display: block;
    z-index: 10;

    /* Interaction styles */
}
.ctapricecard-button:hover {
    background-color: #6a6a6a; /* A darker shade for hover, replacing hover:bg-terracotta-600 */
}
.ctapricecard-button:active {
       @apply scale-95;
}


.btn-shine:hover::after {
    content: '';
    position: absolute;
    top: 0;
    left: -100px;
    width: 200%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.4), transparent);
    animation: shine 1.5s;
}