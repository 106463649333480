    /*Text Visibility Enhancer */
    .contact-text-container-blur {
        background-color: rgba(255, 255, 255, 0.644);
        /* almost imperceptible white background */
        backdrop-filter: blur(10px);
        /* blur effect for the background */
        padding: 15px;
        /* adjusted padding for visual balance */
        border-radius: 8px;
        /* rounded corners for aesthetic */
        display: block;
        /* Change display back to block */
        margin-left: auto;
        /* Auto margins for horizontal centering */
        margin-right: auto;
        /* Auto margins for horizontal centering */
        box-sizing: border-box;
        /* padding included in the element's total width */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        /* Add shadow for depth */
        margin-bottom: 2rem;
        margin-top: 2rem;
        /* Ensure it takes full width for better alignment */
        width: 95%;

    }
    /*Text Visibility Enhancer */
    .contact-text-container-blur-top-banner {
        background-color: rgba(255, 255, 255, 0.644);
        /* almost imperceptible white background */
        backdrop-filter: blur(10px);
        /* blur effect for the background */
        padding: 15px;
        /* adjusted padding for visual balance */
        border-radius: 8px;
        /* rounded corners for aesthetic */
        display: block;
        /* Change display back to block */
        margin-left: auto;
        /* Auto margins for horizontal centering */
        margin-right: auto;
        /* Auto margins for horizontal centering */
        box-sizing: border-box;
        /* padding included in the element's total width */
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        /* Add shadow for depth */
        margin-bottom: 2rem;
        margin-top: 40px;
        /* Ensure it takes full width for better alignment */
        width: 95%;

    }
