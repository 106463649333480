.aboutushero {
  /* Full viewport width */
  width: 100%;
  object-position: top center;
  /* Position the image within the container */

  /* Flexbox properties */
  display: flex;
  flex-grow: 1;
  /* Flex to fill the remaining space vertically */
  flex-direction: column;
  justify-content: flex-end;
  /* Align content at the bottom */

  /* Initial height */
  min-height: 70vh;

  /* Background image */
  background-image: url('../assets/aboutusherotruck2.jpg');
  /* Replace with your image URL */
  background-repeat: no-repeat;
  background-position: center;

  /* Extra styles for aesthetics (optional) */
  background-size: cover;
  /* Additional control over image scaling */
  overflow: hidden;
  /* Hide overflowing content */
}


/* styles.css */
.parallaxHero {
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 70vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  overflow: hidden;
}

